<template>
    <Teleport :to="SEQUENCES_CONTROL_ID" v-if="Object.values(activatorBounding).some(a => a !== 0)">
        <span :style="chunkDefinition.style" v-html="chunkDefinition.text"></span>
    </Teleport>
</template>

<script>
    import activator_position_watcher from '@/sequences/SequenceStepChunk/utils/activator_position_watcher'

    export default {
        render() {
            return null
        },
        name: "ChunkWithText",
        mixins: [activator_position_watcher],
        components: {
            Teleport: () => import('vue2-teleport')
        },
        data: () => ({
            el: null
        }),
        props: {
            chunkDefinition: {
                type: Object,
                required: true
            }
        },
        mounted() {
            // this.el = document.createElement('span')
            // this.el.innerHTML = this.chunkDefinition.text
            //
            // this.el.style = this.chunkDefinition.style
            //
            // document.querySelector(this.SEQUENCES_CONTROL_ID).appendChild(this.el)
        },
        beforeDestroy() {
            // if (this.el)
            //     document.querySelector(this.SEQUENCES_CONTROL_ID).removeChild(this.el)
        }
    }
</script>