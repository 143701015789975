import { getAbsoluteBoundingRect } from '@/utils/misc'

export default {
    props: {
        anchorSelector: {
            type: String,
            required: true
        }
    },
    data: () => ({
        activatorBounding: {},
        resizeObserver: undefined
    }),
    mounted() {
        this.resizeObserver = new ResizeObserver(this.handleWindowResize)
        this.resizeObserver.observe(document.body)
    },
    beforeDestroy() {
        this.resizeObserver.disconnect()
    },
    methods: {
        handleWindowResize() {
            if (document.querySelector(this.anchorSelector)) {
                const boundingRect = getAbsoluteBoundingRect(document.querySelector(this.anchorSelector), {round: true})
                if (Object.values(boundingRect).some(v => v !== 0)) // to avoid weird behavior
                    this.activatorBounding = boundingRect
            }
        }
    }
}